// Normalization

* {
  font-family: 'Open Sans', sans-serif;
}

body,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.App {
  height: 100vh;
}


.booking-page {
  .k-grid tr.k-selected td {
    background-color: transparent !important;
  }

  .k-grid tr.k-selected td.custom-selected-td {
    color: #212529;
    background-color: #bed4f8;
  }
}

// App styles
.k-drawer-container {
  background-color: white;

  .k-drawer {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-right-width: 0 !important;
  }

  &.k-drawer-push .k-drawer {
    position: sticky;
    top: 80px;
  }

  .k-drawer-item {
    user-select: none;
  }
}

.container {
  overflow: hidden;

  .login-form {
    height: 700px;

    input {
      padding: 10px;
    }

    .k-label {
      // line-height: 34px;
    }

    h1 {
      font-size: 32px;
      font-weight: 600;
      color: #495057;
    }

    p {
      color: #495057;
    }

    .logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 32px;
    }
  }

  .container-right {
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .container-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 100px;

    @media (max-width: 768px) {
      padding: 0px 20px;
    }
  }
}

.main-page-container {
  grid-template-columns: 50% 50%;
  height: 100%;
  display: grid;

  @media (max-width: 768px) {
    display: block;
  }
}

.card-container {
  background-color: $white;
  padding: 40px 0;
  text-align: center;

  &.grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 10px;
    row-gap: 1em;
  }

  .card-title {}

  .card-buttons {
    .k-button {
      min-width: 105px;
    }
  }

  .card-ranges {
    .k-daterangepicker {

      .k-textbox-container,
      .k-floating-label-container {
        padding-top: 0;
        min-width: 105px;
      }

      .k-label {
        display: none;
      }
    }
  }

  .card-component {
    grid-column: 1 / -1;
    overflow: hidden;

    .k-command-cell {
      position: sticky;
      left: 0px;
      right: -1px;
      background-color: white !important;

      &.float-action-buttons {
        background-color: #ebebeb !important;
        border-color: transparent;
        display: none;
      }
    }

    .k-command-cell-custom {
      position: sticky;
      left: 0px;
      right: -1px;
      background-color: white !important;
      border-bottom: 1px solid whitesmoke;

      &.float-action-buttons {
        background-color: #ebebeb !important;
        border-color: transparent;
        display: none;
      }
    }

    tr {
      &:hover {

        .k-command-cell-custom,
        .k-command-cell {
          &.float-action-buttons {
            display: table-cell;
          }
        }
      }

      .k-command-cell-custom,
      .k-command-cell {
        &.float-action-buttons {
          &.custom-selected-td {
            display: table-cell;
            background-color: #bed4f8 !important;
            z-index: 1000;
          }
        }
      }

      .float-action-buttons {
        &.k-grid-row-sticky {
          border-top-color: #c0c2cf !important;
          border-bottom-color: #c0c2cf !important;
        }
      }
    }

  }
}

@media (min-width: 480px) {
  .card-container {
    text-align: initial;
    padding: 30px;

    &.grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .card-title {
      grid-column: 1 / 2;
      grid-row: 1;
    }

    .card-buttons {
      text-align: center;
      grid-column: 1 / -1;
      grid-row: 2;
    }

    .card-ranges {
      text-align: right;
      grid-column: 2 / 3;
      grid-row: 1;
    }
  }
}

@media (min-width: 768px) {
  .card-container {
    margin: 25px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    &.grid {
      grid-template-columns: repeat(3, 1fr);
    }

    .card-title {}

    .card-buttons {
      text-align: center;
      grid-column: 2 / 3;
      grid-row: 1;
    }

    .card-ranges {
      text-align: right;
      grid-column: 3 / 4;
    }

    .card-component {
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }
}

// Header
.header {
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 3;

  .nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    color: $white;
  }

  .menu-button {
    flex: 0 0 50px;
    text-align: center;
  }

  .hamburger-icon {
    background-image: url("./../assets/hamburger-icon.svg");
    background-repeat: no-repeat;
    width: 20px;
    cursor: pointer;
  }

  .title {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 25px;
  }

  .k-avatar {
    display: block;
    width: 40px;
    height: 40px;
    flex-basis: 40px;
    margin: 0 25px;
    box-shadow: 0 0 0px 1px $white;
  }

  .settings {
    display: none;
  }
}

@media (min-width: 480px) {
  .header {
    .title {
      flex-direction: row;
      align-items: center;
    }

    .vl {
      border-left: 1px solid $white;
      height: 25px;
      margin: 6px 15px;
    }
  }
}

@media (min-width: 768px) {
  .header {
    .settings {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }

    .k-dropdown {
      max-width: 90px;
      margin-left: 15px;
    }
  }
}

// Dashboard page
.dashboard-page {
  .card-container:last-of-type {
    padding-top: 0;
  }

  .k-grid-toolbar .k-textbox {
    margin-bottom: 10px;
  }

  .k-grid .k-virtual-content,
  .k-grid .k-virtual-content>div {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .k-badge {
    z-index: 1;
  }

  table {
    width: 100% !important;
  }
}

@media (min-width: 480px) {
  .dashboard-page {
    .k-grid-toolbar .k-textbox {
      margin-bottom: 0;
      float: left;
    }

    .k-grid-toolbar .k-button {
      float: right;
    }
  }
}

@media (min-width: 768px) {
  .dashboard-page {
    .card-container:last-of-type {
      padding-top: 30px;
    }
  }
}

// Planning page
.planning-page {
  .card-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .card-title {
    grid-column: 1 / -1;
  }

  .k-hbox {
    padding: 0 20px;
    background: transparent;
    align-items: center;
  }

  .k-card-title {
    font-size: 15px;
    margin-bottom: -6px;
    text-align: left;
  }

  .k-card-subtitle {
    margin-top: 0;
    text-transform: uppercase;
    font-size: 11px;
  }

  .disabled {
    opacity: 0.5;
  }
}

@media (min-width: 480px) {
  .planning-page {
    .card-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (min-width: 768px) {
  .planning-page {
    .k-hbox {
      padding: 0;
      border-width: 0;
    }
  }
}

@media (min-width: 960px) {
  .planning-page {
    .card-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (min-width: 1360px) {
  .planning-page {
    .card-container {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

// Organization Setting page
.organization-setting-page {
  .card-container {
    text-align: left;
  }

  .k-form .k-form-field {
    display: block;
    padding: 0 15px;
  }

  .k-form-field:first-of-type .k-form-field-wrap {
    margin: auto;
  }

  .k-form-field:first-of-type .k-avatar {
    margin: 0 auto 1rem;
  }

  .k-form.k-form-horizontal .k-label {
    width: 100%;
  }

  hr {
    margin-top: 1rem;
    opacity: 0.2;
  }

  .k-form .k-form-buttons {
    justify-content: center;
    width: 100%;

    &>* {
      min-width: 140px;
      width: 100%;
    }

    .k-button {
      width: 100%;
    }
  }

  .k-grid a {
    color: blue;
  }
}

.login-btn {
  width: 100%;
  background-color: #0d6efd;
  padding: 9px 13px;
  color: white;
}

.forgot-pass-btn {
  background-color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  color: #0d6efd;
  font-weight: 500;
  padding: 12px 0;
}

@media (min-width: 480px) {
  .organization-setting-page {
    .k-form .k-form-field {
      display: flex;
    }

    .k-form-field:first-of-type .k-avatar {
      margin: 0;
    }

    .k-form.k-form-horizontal .k-label {
      width: 25%;
    }
  }
}

@media (min-width: 768px) {
  .organization-setting-page {
    .k-form {
      margin: 0 auto;
      padding: 0;
    }

    .k-form .k-form-field {
      padding: 0;
    }
  }
}

.otp-page {
  h1 {
    margin-bottom: 50px;
  }

  .logo {
    display: block !important;
  }

  .ins {
    margin-bottom: 30px;
  }

  .input-separator {
    margin: 6px;
  }

  input {
    font-size: 16px;
  }

  .bottom-container {
    width: 50%;
    margin-left: 0;
  }

  .forgot-pass-btn {
    margin-top: 10px;

    .resend-text {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .back-login-link {
    display: block;
    text-align: center;
    margin-top: 60px;
    color: #0d6efd;
    text-transform: uppercase;
    text-decoration: underline;
  }
}

// Info page
.info-page {
  background-color: $info-background;
  color: $white;

  h1,
  h2,
  h3,
  .component-link {
    font-family: Metric, Helvetica, Arial, sans-serif;
  }

  a:not(.k-button) {
    color: $link-color;
  }

  .content {
    padding-top: 40px;
    margin: 0 auto;
    position: relative;
    max-width: calc(100vw - 253px);
  }

  .kendoka {
    text-align: center;

    img {
      max-width: 280px;
    }
  }

  .section-1 {
    text-align: center;

    h1 {
      font-size: 48px;
      font-weight: bold;
      color: $react-color;
    }

    h2 {
      font-size: 30px;
      margin-bottom: 53px;
    }

    .button-group {
      margin-bottom: 30px;

      .k-button {
        display: block;
        margin: 10px auto;
        min-width: 145px;
        width: 60%;
      }
    }

    .github-link {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      align-content: center;

      .github-text {
        margin-left: 15px;
      }
    }
  }

  .section-2 {
    text-align: center;
    margin-top: 40px;
    padding: 0 15px;
  }

  .section-3 {
    -webkit-columns: 2;
    column-count: 2;
    margin-top: 40px;
    padding: 0 15px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }

  .package-item {
    display: inline-block;
    margin-top: 20px;
  }

  .package-title a {
    font-size: 17px;
    color: #cccccc;
    text-transform: uppercase;
  }

  .component-link {
    padding: 5px 0;

    a {
      font-size: 20px;
    }
  }

  .footer {
    background-image: url("../assets/info-background.svg");
    background-repeat: no-repeat;
    height: 320px;
  }
}

@media (min-width: 480px) {
  .info-page {
    .content {
      padding: 40px 40px 0;
    }

    .kendoka {
      img {
        max-width: 348px;
      }
    }

    .section-1 {
      h1 {
        font-size: 71px;
      }

      h2 {
        font-size: 38px;
      }

      .button-group {
        .k-button {
          display: inline-block;
          margin-right: 10px;
          width: auto;
        }
      }
    }

    .section-3 {
      -webkit-columns: 3;
      column-count: 3;
    }
  }
}

@media (max-width: 480px) {
  .k-form-horizontal .k-form-field-wrap {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .info-page {
    .content {
      padding: 120px 40px 0;
    }

    .kendoka {
      position: absolute;
      right: 0;
      top: 2em;
      text-align: left;
    }

    .section-1,
    .section-2 {
      text-align: left;
      padding: 0;
    }

    .section-3 {
      -webkit-columns: 4;
      column-count: 4;
      padding: 0;
    }

    .footer {
      background-size: cover;
    }
  }
}

.k-notification-group {
  z-index: 99999 !important;
}

.text-secondary {
  color: #666666 !important;
}

.k-chip-list-md{
  gap: 8px !important;
}

.k-chip-solid-base {
  border-radius: 16px !important;
  padding: 6px 8px !important;
  border-color: transparent !important;
  background-color: #D9D9D9 !important;
}

.k-chip-solid-base.k-selected,
.k-chip-solid-base:focus,
.k-chip-solid-base.k-focus {
  background-color: #5359ED !important;
  color: white !important;
  outline: none !important;
  box-shadow: none !important;
}

.split-cell {
  padding: 4px 0;
  border-bottom: 1px solid #e8d7d7;
  height: 46px;
  display: flex;
  align-items: center;
  overflow: hidden;

  div {
    max-height: 46px;
    overflow: auto;
    line-height: 23px;
  }

  &.has-booking {
    color: #f40017;
  }
}

.split-cell:nth-last-child(1) {
  border-bottom: none;
}

.button-split {
  padding: 3px 10px;
  margin: 0 0;
}

.has-event {
  position: relative;
}

.has-event::after {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: #26ea19;
  top: 4px;
  right: 4px;
  border-radius: 50%;
  z-index: -1;
}


.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-24 {
  font-size: 24px;
}

.text-color-primary {
  color: #5359ED;
}

.text-color-white {
  color: white;
}

.text-color-grey {
  color: #B4B4B4;
}

.bg-color-primary {
  background: #5359ED;
}

.bg-color-white {
  background: white !important;
}

.header {
  padding: 20px;
  text-align: center;
}

.content {}

.k-drawer-content {
  overflow: auto;
  height: calc(100vh - 58px);
}

// .k-grid .k-alt {
//   background-color: rgb(0 0 0 / 2%) !important;
// }

// .k-grid tr{
//   background-color: white !important;
// }

.custom-selected-td {
  color: #212529;
  background-color: #bed4f8 !important;
}

.k-scheduler-monthview .k-scheduler-body .k-scheduler-cell {
  padding: 4px 12px 20px;
}

.k-scheduler {
  height: calc(100vh - 250px) !important;
}

.k-scheduler div {
  overflow: auto;
  padding-right: 0px;

  // padding: 4px 12px 20px;
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px
  }

  ::-webkit-scrollbar-button {
    background: #ccc
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent
  }

  ::-webkit-scrollbar-thumb {
    background: #eee;
    height: 0px
  }
}

.k-multiselect {
  // max-height: 71px;
}

.w-400 {
  width: 400px;
}

.w-300 {
  width: 300px;
}

.w-200 {
  width: 200px;
}

.k-tabstrip .k-animation-container {
  width: 100%;
}

.label-status {
  padding: 5px 14px;
  border-radius: 28px;
  font-size: 14px;
  font-weight: 500;
  background-color: #f8f9fa
}

.bg-white {
  background-color: white !important;
}

.k-upload-button {
  margin-right: 8px
}

.k-loading-image {
  color: #66a1f9 !important;
}

.k-text-line-through {
  text-decoration: line-through;
}

.mass-assign-date {
  .k-datepicker {
    width: auto;
  }
}

.cell-assign-container {
  display: grid;
  grid-template-columns: 230px 80px;
  align-items: center;
  column-gap: 10px;
}

.cell-assign-no-booking-container {
  display: grid;
  grid-template-columns: 230px 80px;
  align-items: center;
  column-gap: 10px;
}

.mass-assign tr td {
  opacity: 0.7;

  div {
    position: relative;
    height: 44px;
  }

  &.disabled {
    div {
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        background-color: transparent;
        z-index: 1000;
      }
    }
  }
}

.mass-assign tr.k-selected td {
  opacity: 1;
  background-color: white !important;
  border-bottom: 1px solid #dee2e6;
}

.w-50-percent {
  width: 50% !important;
}

.text-italic {
  font-style: italic;
}

.float-action-buttons {
  background-color: transparent !important;
}

.k-grid-header .k-header.k-grid-header-sticky,
.k-grid-header .k-filter-row .k-grid-header-sticky {
  border-color: #dee2e6;
}

.k-pager-sizes .k-dropdownlist {
  width: 85px;
}

.k-master-row .k-grid-content-sticky {
  border-left-color: #dee2e6;
  border-right-color: #dee2e6;
}

.k-master-row .k-grid-row-sticky {
  border-top-color: #c0c2cf;
  border-bottom-color: #c0c2cf;
}

.text-underline {
  text-decoration: underline;
}

.size-2-rem {

  .k-loading-image::before,
  .k-loading-image::after {
    font-size: 2em;
  }
}

.size-1-rem {

  .k-loading-image::before,
  .k-loading-image::after {
    font-size: 1.25em;
  }

  .k-loading-image::before {
    border-width: 0.15rem;
  }
}

.k-drawer-content {
  border-radius: 16px;
  background-color: #F2F2F2;
}

.k-drawer-container .k-drawer {
  box-shadow: none !important;
}

.k-drawer-mini .k-drawer-wrapper {
  padding: 16px 0px;
}

.k-drawer-item.k-state-selected,
.k-drawer-item.k-selected,
.k-drawer-item.k-selected:hover {
  color: #212529;
  background-color: #6090ef26;
  border-left: 3px solid #545aee;
  padding-left: 13px;
}

.k-drawer-item:focus,
.k-drawer-item.k-state-focused,
.k-drawer-item.k-focus {
  box-shadow: none !important;
}

.card-container {
  margin: 16px;
  box-shadow: none;
}

.k-tabstrip>.k-content {
  border: none;
  padding: 16px 0px;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item {
  border-bottom: 2px solid #D8D8D8;
  color: #5F6067;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-active {
  border: none;
  border-bottom: 2px solid #5359ED;
  font-weight: bold;
  color: #000000;
}

.k-tabstrip-items-wrapper .k-item:hover {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item {
  margin-bottom: 0px;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper {
  border-bottom: none;
}

.k-tabstrip-items .k-link {
  padding: 0.5rem;
}

.opacity-50 {
  opacity: 0.5;
}

.header-container {
  background-size: cover;
  background-position: center;
  margin-top: 24px;

  h1 {
    font-weight: bold;
    font-size: 36px;
    color: white;
  }

  h4 {
    font-size: 22px;
    color: white;
    font-weight: normal;
  }

  @media (max-width: 480px) {
    margin-top: 0;

    h1 {
      font-size: 24px;
    }

    h4 {
      font-size: 16px;
    }
  }
}

.kickstart-container {

  h3 {
    font-size: 22px;
    color: #000000;
  }

  h5 {
    font-size: 16px;
    color: #5F6067;
    font-style: italic;
    font-weight: normal;
  }
}

.k-stepper .k-step-link {
  overflow: auto !important;
}

.k-stepper .k-step-label .k-step-text {
  max-width: none !important;

  @media (max-width: 480px) {
    display: none;
    font-size: 12px;
  }
}

.k-step-current .k-step-text {
  @media (max-width: 480px) {
    display: inline-block !important;
  }
}

.k-step-list-horizontal .k-step-link {
  max-width: none !important;
}

.k-stepper .k-step-indicator {
  cursor: pointer;
}

.k-dropdownlist {
  border-radius: 8px !important;
}

.text-label-input{
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: black;
  min-height: 1.5rem;
  border: 1px solid;
  border-radius: 8px;
  border-color: #e4e7eb !important;
  background-color: #F2F2F2 !important;
}