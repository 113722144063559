// Global variables here
$background-color: #d4d6e4;
$info-background: #151950;
$react-color: #53d2fa;
$link-color: #71a6ff;
$white: #ffffff;

$tiger-team: #ff6358;
$lemon-team: #ffd246;
$organic-team: #55ab1d;
$ocean-team: #28b4c8;
