.h-24px {
    height: 24px;
}

.bg-white {
    background-color: white;
}

.bg-white-50 {
    background-color: #F9F9F9;
}

.bg-grey-50 {
    background-color: #E5E6EB;
}

.h-26px {
    height: 26px;
}

.h-28px {
    height: 28px;
}

.d-block {
    display: block;
}

.d-none {
    display: none;
}

.rounded-8 {
    border-radius: 8px !important;
}

.rounded-16 {
    border-radius: 16px !important;
}

.grid-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.grid-1-1-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.grid-4-slot {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.gap-10 {
    gap: 10px;
}

.mt--3 {
    margin-top: -3px;
}

.mx--3 {
    margin-left: -12px;
    margin-right: -12px;
}

.mx--4 {
    margin-left: -16px;
    margin-right: -16px;
}

.m-auto {
    margin: auto;
}

.max-width-300px {
    max-width: 300px;
}

.k-container {
    padding-left: 10vw;
    padding-right: 10vw;

    @media (max-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (max-width: 480px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.k-content-container {
    padding-left: 10vw;
    padding-right: 10vw;

    @media (max-width: 768px) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media (max-width: 480px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.k-card-container {
    overflow: auto;

    .k-padding-card {
        padding-left: 50px;
        padding-right: 50px;
    }

    .card-title {
        font-size: 22px;
        color: #5359ED;
        font-weight: bold;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    @media (max-width: 480px) {
        .k-padding-card {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.k-row-container {
    display: grid;
    grid-template-columns: 5fr 3fr;
    grid-gap: 32px;

    &.one-item {
        grid-template-columns: 1fr;
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}

.k-info-container {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 12px;
    margin-bottom: 16px;

    .info-title {
        font-weight: bold;
    }

    @media (max-width: 480px) {
        display: block;

        .info-title {
            &::after {
                content: ":";
            }

            margin-right: 8px;
        }
    }
}

.border-grey {
    border: 1px solid #D9D9D9
}

.lablel-dark {
    font-weight: bold;
    color: black;
}

.lablel-grey {
    font-weight: bold;
    color: #B4B4B4;
}

.lablel-primary {
    font-weight: bold;
    color: #5359ED;
}

.lablel-italic {
    font-weight: bold;
    font-style: italic;
}

.app-button {
    font-weight: bold;

    &.primary {
        color: white;
        background-color: #5359ED;
    }

    &.large {
        padding: 16px 58px;
    }

    &.medium {
        padding: 8px 20px;
        font-size: 14px;
    }

    @media (max-width: 480px) {
        &.mobile-full {
            width: 100%;
        }
    }
}

.page-loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-loading-overlay {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: #fff8f870;
    z-index: 9999;
}