body {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

html,
body {
  &::-webkit-scrollbar {
    display: none;
  }
}

@import "./styles/main.scss";
@import "./styles/systems/utils.scss";

.k-drawer-content {
  border: 0.5px solid #f2f2f2;
}

.card-container {
  padding-bottom: 10px;
}

.k-tabstrip {
  .k-content {
    padding-bottom: 0px;
  }
}

.k-widget.k-drawer.k-drawer-start {
  z-index: 1000;
}

.k-chip-list {
  max-height: 110px;
  overflow: auto;
  padding: 4px;
}

.booking-export-container {
  display: grid;
  grid-template-columns: 1fr 1fr 200px;

  .k-filtercell {
    align-items: flex-start;
    margin-top: 3px;
  }
}

.utilization-export-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 200px;

  .k-filtercell {
    align-items: flex-start;
    margin-top: 3px;
  }
}

.k-calendar-navigation {
  z-index: 1;
}

.k-icon.k-i-expand,
.k-icon.k-i-collapse {
  transform: scale(1.3);
}

.tree-select-filter {
  .placeholder {
    display: inline-block;
  }

  &:hover,
  &.has-value {
    .placeholder {
      display: none;
    }
  }
}

.k-upload .k-dropzone {
  padding: 4px 8px;

  .k-upload-button {
    padding: 2px;
  }
}

.operator-custom {
  cursor: pointer;

  &:hover {
    background-color: #e9ecef;
  }

  &.active {
    border-color: #0d6efd;
    color: white;
    background-color: #0d6efd;
  }
}

.tree-select-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  .react-dropdown-tree-select {
    width: auto;
  }

  .react-dropdown-tree-select .dropdown {
    width: 100%;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger {
    width: 100%;
    box-sizing: border-box;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after,
  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    display: none;
  }

  .react-dropdown-tree-select .dropdown .dropdown-content {
    width: calc(100% - 8px);
    border-color: #dee2e6;
    color: #212529;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 3%), 0 4px 5px 0 rgb(0 0 0 / 4%);
    z-index: 9999;
    max-height: 188px;
    overflow: auto;
    border-radius: 0.25rem;
    border: 1px solid #dee2e6;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger {
    border-color: #ced4da;
    color: #495057;
    background-color: #ffffff;
    border-radius: 0.25rem;
    max-height: 100px;
    overflow: auto;
    padding-right: 20px;
    border-radius: 8px;
  }

  .tag-item .search {
    &::placeholder {}

    border: none;
    min-width: 200px;
    padding: 6px 4px 3px 4px;
  }

  .tag-item input::placeholder {
    font-size: 16px;
  }

  .toggle {
    font-style: normal;
  }

  .toggle.expanded:after {
    content: "\25bc";
    font-size: 16px;
  }

  .toggle.collapsed:after {
    content: "\25b6";
    font-size: 16px;
  }

  .checkbox-item {

    &:checked {
      border-color: #0d6efd;
      color: white;
      background-color: #0d6efd;

      // background-image: $kendo-checkbox-checked-image;
      &::after {
        content: "\2713";
        margin-left: 2px;
        margin-top: -1px;
        display: inline-block;
      }
    }

    border-color: #ced4da;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    line-height: initial;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    flex: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem
  }

  .checkbox-item {
    margin-right: 16px;
    margin-left: 6px;
  }

  .node>label {
    font-size: 16px;
    color: #212529;
  }

  .node.focused {
    background-color: white;
  }

  .tag {
    border-color: #bcbebf;
    color: #212529;
    background-color: #edeeee;
    padding: 0.25rem 0.25rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: 0.25rem;
    padding-left: 8px;
    padding-top: 2px;

  }

  .tag-remove {
    font-size: 0px;
    color: #212529;
    padding-left: 10px;
    position: relative;
    height: 16px;
    width: 16px;
    vertical-align: middle;
    margin-left: 4px;

    &::before {
      content: "\00D7";
      font-size: 24px;
      line-height: 12px;
      position: absolute;
      top: 2px;
      left: 0px;
    }
  }

  .tag-item {
    margin: 2px;
    border-radius: 0.25rem;
  }

  .tree-select-container .tag-item {
    display: block;
    min-height: 24px;
  }
}

.k-multiselect {
  border-radius: 8px
}

.k-datepicker {
  border-color: #D8D8D8 !important;
  border-radius: 8px !important;

  .k-dateinput.k-input {
    background-color: white;
    color: #5F6067;

    input {
      padding-left: 8px;
      background-color: white;
    }
  }

  >button,
  >button:hover {
    background-color: white;
    border: none;
  }

  .k-button-icon {
    color: #504f4f;
  }
}

.button-submit {
  background-color: #5359ED;
  padding: 9px 10.5px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 8px;
  gap: 8px;
}

.button-outline-white {
  background-color: #F2F2F2;
  padding: 9px 10.5px;
  font-size: 16px;
  color: #000000;
  border: none;
  border-radius: 8px;
  gap: 8px;
}

// .k-grid-header-wrap table{ 
//   display: block;
// }

.k-grid-header-wrap {
  background-color: white;
  margin-right: 0;
}

.k-grid-table {
  display: table !important;
}

.k-column-title {
  font-weight: bold;
  font-size: 14px;
}


.k-grid-header .k-header {
  border: none;
  background-color: #F2F2F2;
}

.k-filter-row {
  background-color: white;
}

.k-filter-row th {
  border: none;
  background-color: white;
}

.k-grid-header .k-filter-row .k-grid-header-sticky {
  background-color: white;
}

.k-grid {
  border: none;
}

.k-grid-header {
  border: 1px solid #f2f2f2;
  border-bottom: none;
}

.k-grid-container {
  border: 1px solid #f2f2f2;
}

.k-grid-pager {
  background-color: white;
}

th {
  .k-filtercell .k-input {
    border-radius: 8px;
  }

  .k-filtercell .k-picker,
  .k-filtercell>span>.k-button {
    border-radius: 8px;
  }

  .k-datepicker .k-dateinput.k-input {
    background-color: white;
  }

  .k-filtercell>span>.k-button {
    background-color: white;
  }
}

.k-grid td {
  border-left-color: transparent;
}

// .k-grid .k-header.k-grid-header-sticky {
//   background-color: transparent;
// }

.k-grid td.k-hierarchy-cell {
  border-left-color: transparent;
  border-right-color: transparent;
}

.k-grid td.k-grid-content-sticky {
  border-left-color: transparent;
  border-right-color: transparent;
}

.k-scheduler {
  .k-toolbar {

    background-color: white;
    margin: -1px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 16px;

    .k-scheduler-navigation {
      border-radius: 8px;

      .k-button-solid-base {
        background-color: #F2F2F2;
        border-color: #F2F2F2;
        color: #5F6067;
      }
    }

    .k-datepicker {
      border-radius: 8px;
      border-color: #B4B4B4;
    }
  }

  .k-scheduler-footer {
    display: none;
  }

  .k-scheduler-views {
    border-radius: 8px;

    button {
      background-color: #F2F2F2;
      color: #5F6067;
      padding: 5px 12px;

      &.k-selected {
        background-color: #5359ED;
        color: white;
      }
    }
  }

  .k-scheduler-row {
    .k-heading-cell {
      background-color: #F2F2F2;
      border-right: none;
      padding: 6px 12px;
      text-transform: uppercase;
    }
  }
}

// calendar
.k-calendar .k-calendar-td.k-state-selected .k-link,
.k-calendar .k-calendar-td.k-selected .k-link {
  border-radius: 8px;
}

.k-calendar .k-calendar-view .k-today .k-link {
  border-radius: 8px;
}

.k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-state-hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-link {
  border-radius: 8px;
}

.k-button-solid-primary {
  border-color: #5359ED !important;
  color: white !important;
  background-color: #5359ED !important;
  border-radius: 8px;
}

.k-button-solid-base {
  border-color: #E5E6EB;
  color: black;
  background-color: #E5E6EB;
}

.custom-grid-navigate {
  font-size: 16px;
  justify-content: end;
  background-color: white;
  border: none;
  margin-top: 4px;
  padding-right: 0px;

  .k-pager-nav {

    &.k-disabled {
      color: #a8a8a8;
    }

    margin: 0px 4px;
    border: none;
    background-color: #F2F2F2;
    border-radius: 4px;
    color: black;
    font-size: 16px;
    min-width: calc(1em + 0.75rem + 2px);
    height: calc(1em + 0.75rem + 2px);
  }


  .k-pager-info {
    flex: none;
    order: 1;
  }

  .k-link.k-pager-nav.k-pager-first {
    order: 2;
  }

  .k-link.k-pager-nav:nth-of-type(2) {
    order: 3;
  }

  .k-pager-numbers-wrap {
    order: 4;

    .k-pager-numbers li a {
      min-width: calc(1em + 0.75rem + 2px);
      height: calc(1em + 0.75rem + 2px);

      &.k-selected {
        background-color: #5359ED;
        color: white;
      }

      margin: 0px 4px;
      background-color: white;
      border: none;
      border-radius: 4px;
      color: black;
      font-size: 16px;
    }
  }

  .k-link.k-pager-nav:nth-of-type(3) {
    order: 5;
  }

  .k-link.k-pager-nav.k-pager-last {
    order: 6;
  }

  .k-pager-sizes.k-label {
    order: 7;
    margin-right: 0px;

    .k-dropdownlist {
      height: 30px;
      background-color: #F8F9FA;
    }
  }
}

.k-dialog-wrapper .k-dialog {
  border-radius: 12px;
  background-color: #F8F9FA;

  .k-window-titlebar {
    border-color: transparent;
    font-size: 22px;
    font-weight: bold;
    padding: 16px 26px 0;
  }

  .k-window-actions {
    margin-right: -16px;

    button {
      border-radius: 8px;
    }
  }

  .k-form-buttons {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .form-container {
    padding: 0 10px 4px;
    overflow: auto;
    max-height: 80vh;
  }

  .k-form-horizontal .k-form-field>.k-label,
  .k-form-horizontal .k-form-field>kendo-label,
  .k-form-horizontal .k-form-field>.k-form-label {
    align-items: flex-start;
    color: #817d7d;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }

  .k-chip-solid-base {
    border-radius: 8px !important;
    border-color: transparent !important;
  }

  input,
  textarea,
  .k-input-solid,
  .k-datepicker,
  .k-picker-solid {
    border-radius: 8px;
  }

  .k-checkbox {
    margin-top: 4px;
  }

  .k-form-error {
    font-weight: 500;
  }
}

.k-form-field {
  display: block !important;
  margin-bottom: 16px;
  margin-top: 0px !important;

  .k-label {
    width: auto !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    text-align: start !important;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 8px;
  }

  .k-form-field-wrap {
    max-width: none !important;
    position: relative !important;

    input {
      border-radius: 8px;
      border-color: #D9D9D9;
    }

    .k-field-clearer {
      position: absolute;
      right: -30px;
      top: 0px;
    }
  }
}

.edit-org {
  margin: inherit !important;
  max-width: 70%;

  .k-form-field>.k-label,
  .k-form-field>kendo-label,
  .k-form-field>.k-form-label {
    align-items: flex-start;
    color: #817d7d;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }

  input,
  textarea,
  .k-input-solid,
  .k-datepicker,
  .k-picker-solid {
    border-radius: 8px;
  }

  .k-checkbox {
    margin-top: 4px;
  }

  .k-form-error {
    font-weight: 500;
  }

  .k-editor {
    border-radius: 0 0 8px 8px
  }
}

.authen-form {

  input,
  textarea,
  .k-input-solid {
    border-radius: 8px;
  }
}

.mass-assign {
  .k-datepicker .k-button-icon {
    color: white;
  }
}

.k-grid-toolbar {
  border: none;
  background-color: white;
}

.dialog-confirm-inside {
  .k-dialog-content {
    padding: 0px;
  }
}

.dialog-bottom-no-line {
  .k-dialog-buttongroup {
    border-color: transparent;
  }
}

.p-label {
  margin-top: 7px;
  margin-bottom: 0px;
}

.blockbuilder-branding {
  background: #f9f9f9 !important;
  border: none !important;
}

.blockbuilder-branding * {
  display: none !important;
}

.form-dropdown-clearer {
  position: absolute;
  right: 22px;
  z-index: 100;
  cursor: pointer;
}

.chiplist-group {
  border-radius: 8px;
  max-height: 400px;
  overflow: auto;
}

.chiplist-container {
  max-height: 500px;
  overflow: auto;
}

.k-upload {
  border-radius: 8px;

  .k-dropzone {
    background-color: white;
    border-radius: 8px;
    padding: 5px 8px;

    .k-upload-button {
      order: 1;
      margin-left: 8px;
      margin-right: -4px;
      padding-right: 0;
      background-color: #D9D9D9;

      span {
        font-size: 14px;
      }
    }

    .k-dropzone-hint {
      order: 0;
      font-size: 14px;
    }
  }
}

.sample-file-download {
  background-color: #D9D9D9;
  border-radius: 8px;
  border: none;
  margin-left: 8px;
  font-size: 15px;
}

.k-upload .k-upload-files .k-upload-status .k-icon.k-retry {
  display: none !important;
}

.k-button:disabled,
.k-button.k-disabled {
  opacity: 0.55;
}

.k-radio-item.k-disabled {
  opacity: 0.8;
}

.span-indent {
  width: 8px;
  display: inline-block;
}

td {
  &:hover {
    .button-copy-float {
      display: flex;
    }
  }
}

.k-selected,
.custom-selected-td {
  .button-copy-float {
    background-color: #d9e6fc;
  }
}

.button-copy-float {
  &:hover {
    opacity: 1;
  }

  position: absolute;
  top: 16px;
  right: 2px;
  padding: 4px;
  border: none;
  background-color: #f2f2f2;
  border-radius: 8px;
  opacity: 0.9;
  cursor: pointer;
  display: none;
}

.custom-info-icon {
  &:hover {
    opacity: 1;
  }

  position: absolute;
  top: 0x;
  left: 4px;
  border-radius: 8px;
  opacity: 0.6;
  cursor: pointer;
}

.dialog-announcement {

  .k-window {
    border-radius: 4px;
  }

  .k-window-title {
    justify-content: center;
    margin-left: 20px;
    padding-bottom: 20px;
  }

  .k-dialog-title {
    font-size: 26px;
    color: #e03600;
  }

  .icon-warning {
    font-size: 100px;
    color: #ff9800;
  }

  .content {
    font-size: 20px;
  }

  .k-dialog-buttongroup {
    border: none;
    padding-bottom: 16px;
    justify-content: center;

    button {
      flex: none;
      padding: 6px 50px;
      border-color: #ff9901;
      color: white;
      background-color: #ff9901;

    }
  }
}

.toast-dialog {
  .k-dialog-buttongroup {
    border: none !important;
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }
}

.k-picker-solid {
  background-color: white !important;

  &.k-disabled {
    background-color: #e4e7eb !important;
  }
}

.k-input-solid {
  &:disabled {
    background-color: #e4e7eb !important;
  }
}

.k-stepper .k-step-current .k-step-indicator {
  border: none !important;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 12%);
  margin: 0px;
  padding: 0px;
  margin: 3px;
}

.k-stepper .k-step-indicator {
  border: none !important;
  background-color: #D8D8D8 !important;
  color: white !important;
}

.k-stepper .k-step-current .k-step-indicator {
  background-color: #0d6efd !important;
}

.k-stepper .k-step-done .k-step-indicator {
  background-color: #0d6efd !important;
}

.k-stepper .k-step-focus .k-step-indicator::after,
.k-stepper .k-step-link:focus .k-step-indicator::after {
  display: none !important;
}